import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"
import Section from "../components/Section"
import Triangle from "../components/Triangle"

import { backgroundDark, primaryDark, secondary } from "../styles/colors.module.scss"

const Background = () => (
  <div>
    <Triangle
      color={backgroundDark}
      height={10}
      width={100}
      invertY
    />

    <Triangle
      color={secondary}
      height={20}
      width={120}
      invertX
      invertY
    />

    <Triangle
      color={primaryDark}
      height={40}
      width={70}
      invertX
    />

    <Triangle
      color={backgroundDark}
      height={20}
      width={100}
    />
  </div>
)

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;

  > * {
    margin: 1em
  }
`

const AboutMe = () => {
  const { contentfulImage } = useStaticQuery(graphql`
  {
    contentfulImage(title: {eq: "Hi Bitmoji"}) {
      photo {
        fluid(maxWidth: 398) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
  `)
  return (
    <Section Background={Background}>
      <FlexContainer>
        <div>
          <h1>About Me</h1>
          <p>My name is Jeremy Moorman and I'm a software developer.</p>
          <p>I built this website to show off some of my personal skills.</p>
          <ul>
            <li>React</li>
            <li>Gatsby</li>
            <li>Typescript</li>
            <li>CMS - Contentful</li>
            <li>CI/CD - Github Actions</li>
            <li>Hosting - AWS S3</li>
          </ul>
        </div>
        <div style={{width: "100%", maxWidth: 398}}>
          <Img fluid={contentfulImage.photo.fluid} />
        </div>
      </FlexContainer>
    </Section>
  )
}

export default AboutMe