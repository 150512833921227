import React from "react" 
import styled from "styled-components"
import { background } from "../styles/colors.module.scss"

interface CardProps {
  className?: string
}

const StyledDiv = styled.div`
  box-shadow: 0 0 8px 4px rgba(0,0,0,0.2);
  padding: 1em;
  background: ${background};
  border-radius: 12px;
`

const Card: React.FC<CardProps> = ({ children, className }) => (
  <StyledDiv className={className}>
    {children}
  </StyledDiv>
)

export default Card