import React from "react"
import Section from "../components/Section"
import Triangle from "../components/Triangle"

import { backgroundDark, primary, primaryDark, secondary } from "../styles/colors.module.scss"

interface LandingSectionData {
  contentfulAbout: {
    name: string,
    roles: [],
    socialLinks: [{
      id: string,
      url: string,
      name: string,
      fontAwsomeIcon: string
    }]
  }
}

const Background = () => (
  <div>
    <Triangle
      color={backgroundDark}
      height={80}
      width={60}
    />

    <Triangle
      color={secondary}
      height={80}
      width={35}
    />

    <Triangle
      color={primaryDark}
      height={35}
      width={60}
      invertX
    />

    <Triangle
      color={backgroundDark}
      height={20}
      width={100}
      invertX
      invertY
    />
  </div>
)

const Landing = () => (
  <Section Background={Background}>
    <h1 style={{ color: primary, textAlign: "center" }}>Hello, I'm Jeremy!</h1>
  </Section>
)

export default Landing