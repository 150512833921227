import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Document } from "@contentful/rich-text-types"

import Img, { FixedObject } from "gatsby-image"
import moment from "moment"
import React from "react"
import styled from "styled-components"

import Card from "./Card"
import SkillsList from "./SkillsList"

interface ExperienceCardProps {
  company: string,
  logo?: FixedObject,
  skills: string[],
  description: Document
  startDate: Date,
  endDate?: Date,
}

const GridCard = styled(Card)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 4fr 1fr;
  grid-template-areas:
    "company logo"
    "description description"
    "skills skills";
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  width: 600px;
  margin: 10px;
`

const Description = styled.div`
  grid-area: description;
  align-self: center;
`

const Skills = styled(SkillsList)`
  grid-area: skills;
  align-self: end;
`

const Logo = styled(Img)`
  grid-area: logo;
  align-self: center;
  justify-self: center;
`

const Company = styled.div`
  grid-area: company;
`

const ExperienceCard: React.FC<ExperienceCardProps> = ({company, logo, skills, description, startDate, endDate}) => {
  const monthDiff = moment(endDate).diff(startDate, "months")
  const years = Math.floor(monthDiff / 12)
  const months = monthDiff % 12
  const timeStrings = []
  
  if (years > 1) {
    timeStrings.push(`${years} years`)
  } else if (years === 1) {
    timeStrings.push(`${years} year`)
  }

  if (months > 1) {
    timeStrings.push(`${months} months`)
  } else if (months === 1) {
    timeStrings.push(`${months} month`)
  }

  return (
    <GridCard>
      <Company>
        <h3 style={{marginBottom: 0}}>{company}</h3>
        <i>{timeStrings.join(" ")}</i>
      </Company>
      {logo && <Logo fixed={logo} />}
      <Description>
        {documentToReactComponents(description)}
      </Description>
      <Skills skills={skills} />
    </GridCard>
  )
}

export default ExperienceCard