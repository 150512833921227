import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

import ExperienceCard from "../components/ExperienceCard"
import Section from "../components/Section"
import Triangle from "../components/Triangle"

import { backgroundDark, primaryDark, secondary } from "../styles/colors.module.scss"

const Background = () => (
  <div>
    <Triangle
      color={backgroundDark}
      height={10}
      width={100}
    />

    <Triangle
      color={secondary}
      height={10}
      width={120}
      invertX
    />

    <Triangle
      color={secondary}
      height={10}
      width={120}
      invertY
    />

    <Triangle
      color={primaryDark}
      height={40}
      width={70}
      invertX
      invertY
    />
  </div>
)

const FlexDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
`

const Experience = () => {
  const { allContentfulJob } = useStaticQuery(graphql`
  {
    allContentfulJob {
      nodes {
        company
        startDate
        endDate
        logo {
          fixed(height: 60) {
            ...GatsbyContentfulFixed
          }
        }
        description {
          json
        }
        skills
      }
    }
  }
  `)

  return (
    <Section Background={Background}>
      <h1>Work Experience</h1>
      <FlexDiv>
        {allContentfulJob.nodes.map((job: any, index: number) => {
          const logo = job.logo ? job.logo.fixed : undefined
          const endDate = job.endDate ? new Date(job.endDate) : undefined
          return (
            <ExperienceCard key={index}
                company={job.company}
                logo={logo}
                skills={job.skills}
                description={job.description.json}
                startDate={new Date(job.startDate)}
                endDate={endDate}
            />
          )
        })}
      </FlexDiv>
    </Section>
  )
}

export default Experience