import React from "react" 
import styled from "styled-components"

interface SkillsListProps {
  className?: string,
  skills: string[]
}

const StyledCode = styled.code`
  margin: 0.25em;
  padding: 0 0.5em 0 0.5em;
  border-radius: 20px;
  :hover {
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    cursor: default;
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: evenly;
  max-width: 100%;
`

const SkillsList: React.FC<SkillsListProps> = ({ skills, className }) => (
  <StyledDiv className={className}>
    {skills.map((skill, index) => <StyledCode key={index}>{skill}</StyledCode>)}
  </StyledDiv>
)

export default SkillsList